import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import {
  VytalMapInnerContainer,
  ClusterContainer,
  ClusterCount,
  ClusterIcon,
  PinContainer,
  PinContainerSelected,
} from "./VytalMapContainer";
import mapStyle from "./mapStyle";

const { REACT_APP_GOOGLE_API_KEY } = process.env;

const VytalMap = ({
  language,
  defaultCenter,
  defaultZoom,
  onGoogleApiLoaded,
  onChange,
  clusters,
  onClusterClick,
  onPinClick,
  selectedStore,
}) => {
  // This is necessary because of a bug in the google-map-react module
  const Cluster = ({ children }) => <div>{children}</div>;
  const Pin = ({ children }) => <div>{children}</div>;
 
  return (
    <VytalMapInnerContainer>
      <GoogleMapReact
        options={{
          styles: mapStyle,
          minZoom: 1,
          // disableDoubleClickZoom: true,
          // gestureHandling: "cooperative",
        }}
        bootstrapURLKeys={{
          key: REACT_APP_GOOGLE_API_KEY,
          libraries: ["places"],
          language: language,
        }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
        debounced
        resetBoundsOnResize={false}
        onChange={onChange}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount,
          } = cluster.properties;

          if (isCluster) {
            return (
              <Cluster
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <ClusterContainer
                  onClick={() => {
                    onClusterClick(cluster);
                  }}
                >
                  <ClusterCount>{pointCount}</ClusterCount>
                  <ClusterIcon />
                </ClusterContainer>
              </Cluster>
            );
          }
          return (
            <Pin
              key={`pin-${cluster.properties.storeId}`}
              lat={latitude}
              lng={longitude}
            >
              {selectedStore.id === cluster.properties.storeId && (
                <PinContainerSelected />
              )}
              {selectedStore.id !== cluster.properties.storeId && (
                <PinContainer
                  selected={selectedStore.id === cluster.properties.storeId}
                  onClick={() => {
                    onPinClick(cluster);
                  }}
                />
              )}
            </Pin>
          );
        })}
      </GoogleMapReact>
    </VytalMapInnerContainer>
  );
};



VytalMap.defaultProps = {
  language: "en",
  // Köln
  defaultCenter: { lat: 50.935173, lng: 6.953101 },
  defaultZoom: 12,
  onGoogleApiLoaded: () => {},
  onChange: () => {},
  clusters: [],
  onClusterClick: () => {},
  onPinClick: () => {},
  selectedStore: {},
};

VytalMap.propTypes = {
  language: PropTypes.string,
  defaultCenter: PropTypes.object,
  defaultZoom: PropTypes.number,
  onGoogleApiLoaded: PropTypes.func,
  onChange: PropTypes.func,
  clusters: PropTypes.array,
  onClusterClick: PropTypes.func,
  onPinClick: PropTypes.func,
  selectedStore: PropTypes.object,
};

export default VytalMap;
