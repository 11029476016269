import styled from "styled-components/macro";
import {COLOR} from "./styles";

export const AppContainer = styled.div`
  height: 100%;
  position: relative;
`;



export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${COLOR.OVERLAY_60};
  color: ${COLOR.WHITE};
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
