import styled from "styled-components/macro";
import { COLOR, BREAKPOINTS } from "../styles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ReactComponent as Clock } from "../assets/clock.svg";
import { ReactComponent as PinSelected } from "../assets/pin-selected.svg";
import { ReactComponent as Wolt } from "../assets/ServiceLogoWolt.svg";
import { ReactComponent as Lieferando } from "../assets/ServiceLogoLieferando.svg";
import { ReactComponent as Eatic } from "../assets/ServiceLogoEatic.svg";
import { ReactComponent as Mjam } from "../assets/ServiceLogoMjam.svg";
import { ReactComponent as HabGusta } from "../assets/ServiceLogoHabGusta.svg";
import { ReactComponent as Preorder } from "../assets/ServiceLogoVytalPickup.svg";
import { ReactComponent as Delivery } from "../assets/ServiceLogoVytalDelivery.svg";

const APP_ICON_WIDTH = 38;
const APP_ICON_BORDER_RADIUS = 8;

export const VytalInnerListContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  margin: 0;
  padding-right: 0;
  height: 100%;

  @media screen and (min-width: ${BREAKPOINTS.laptop}px) {
    margin: 0 1em;
    padding-right: 1em;
    min-width: 630px;
  }
`;

export const StoreCard = styled.div`
  position: relative;
  background-color: ${(props) => (props.selected ? COLOR.WHITE : "#fefefe")};
  border-radius: 8px;
  // box-shadow: 0 2px 2px 0 #1d1d1d8a;
  margin: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  overflow: hidden;
  transition: margin 0.3s ease-out; // note that we're transitioning transform, not height!
  height: ${(props) => (props.selected ? "auto" : "230px")};

  @media screen and (min-width: ${BREAKPOINTS.mobileLandscape}px) {
    margin: 16px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}px) {
    flex-direction: row;
    /* box-shadow: ${(props) =>
      props.selected
        ? "0px 4px 10px 0px #1d1d1d8a"
        : "0px 2px 2px 0px #1d1d1d8a"};
   
     */
    margin: ${(props) => (props.selected ? "10px 10px" : "0 35px 10px 35px")};
    height: ${(props) => (props.selected ? "auto" : "162px")};
    // max-width: none;
    // width: auto;
    &:hover {
      transform: ${(props) => (props.selected ? "none" : "scale(1.02)")};
    }
  }
`;

export const GradientContainer = styled.div`
  height: 30px;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  position: absolute;
  bottom: 1em;
  left: 0;
  display: ${(props) => (props.selected ? "none" : "block")};
`;

export const WoltAppIcon = styled(Wolt)`
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_WIDTH}px;
  background-color: ${COLOR.WOLT};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin-left: 5px;
`;

export const LieferandoAppIcon = styled(Lieferando)`
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_WIDTH}px;
  background-color: ${COLOR.LIEFERANDO};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin-left: 5px;
`;

export const MjamAppIcon = styled(Mjam)`
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_WIDTH}px;
  background-color: ${COLOR.MJAM};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin-left: 5px;
`;

export const EaticAppIcon = styled(Eatic)`
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_WIDTH}px;
  background-color: ${COLOR.EATIC};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin-left: 5px;
`;

export const HabGustaAppIcon = styled(HabGusta)`
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_WIDTH}px;
  background-color: ${COLOR.HAB_GUSTA};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin-left: 5px;
`;

export const DeliveryAppIcon = styled(Delivery)`
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_WIDTH}px;
  background-color: ${COLOR.VY_GREEN};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin-left: 5px;

  &.path {
    fill: ${COLOR.VY_BLACK};
  }
`;

export const PreorderAppIcon = styled(Preorder)`
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_WIDTH}px;
  background-color: ${COLOR.VY_GREEN};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin-left: 5px;
`;

export const StoreBanner = styled.div`
  flex-shrink: 0;
  position: relative;
  display: flex;
  width: 100%;
  height: 100px;
  transition: width 0.3s ease-out;
  z-index: 1;

  @media screen and (min-width: ${BREAKPOINTS.laptop}px) {
    width: ${(props) => (props.selected ? "235px" : "180px")};
    height: auto;
  }
`;

export const LazyBannerImage = styled(LazyLoadImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const OverlayContainer = styled.div`
  display: flex;
  background-color: #000000b3;
  color: ${COLOR.WHITE};
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
`;

export const OverlayText = styled.p`
  text-align: center;
  line-height: 1.3;
`;

export const StoreCardPin = styled(PinSelected)`
  width: 36px;
  height: 40px;
  margin-right: 16px;
`;

export const StoreDetails = styled.div`
  margin: 16px;
  overflow: hidden;
  order: 1;

  @media screen and (min-width: ${BREAKPOINTS.laptop}px) {
    order: 0;
    margin: 1em 0 1em 1em;
    padding-right: 1em;
    width: 100%;
  }
`;

export const StoreHeader = styled.div`
  align-items: center;
  margin-bottom: 1em;
  display: ${(props) => (props.selected ? "flex" : "none")};
`;

export const StoreName = styled.h3`
  margin: 0;
  font-size: 20px;
  line-height: 1.2;
  font-weight: bold;
`;

export const StoreAddress = styled.p`
  font-size: 16px;
  line-height: 1.3;
  margin: 0.2em 0.5em 0.2em 0;
`;

export const WrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ClockIcon = styled(Clock)`
  width: 16px;
  height: 16px;
  margin-right: 0.2em;
`;

export const StoreHours = styled.div`
  font-size: 16px;
  line-height: 1.3;
  margin-right: 0.5em;
  align-items: center;
  display: flex;
  flex-shrink: 0;
`;

export const WebsiteContainer = styled.div`
  font-size: 16px;
  line-height: 1.3;
  align-items: center;
  display: flex;
`;

export const StoreWebsite = styled.a`
  font-size: 16px;
  color: ${COLOR.VY_GREEN};
  text-decoration: none;

  &:hover {
    color: ${COLOR.VY_GREEN};
    text-decoration: underline;
  }
`;

export const StoreTxt = styled.p`
  margin: 0.3em 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;
`;
