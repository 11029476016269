import React from "react";
import ReactDOM from "react-dom";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
} from "@apollo/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./i18n";

//const { REACT_APP_APOLLO_URI } = process.env;
const { REACT_APP_APOLLO_URI } = 'https://colugo.pickpack.de';
const httpLink = new HttpLink({
  uri: 'https://colugo.pickpack.de',
  name: "vytal-web",
  headers: {
    accept: "*/*",
    "sec-fetch-dest": "script",
    authorization: "ANONYMOUS",
    "content-type": "application/json",
    "client-name": "vytal-website",
  },
});

//    "sec-fetch-site": "cross-site", "sec-fetch-mode": "no-cors",

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
