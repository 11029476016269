const translations = {
  en: {
    translation: {
      type_your_city: "Start by typing your city...",
      no_partners_in_area:
        "Unfortunately, we couldn't find any partners in this area.",
      suggest_partner: "Suggest a partner!",
      today: "Today",
      hourSuffix: "",
      website: "Visit web",
      coming_soon: "Coming soon",
      available_soon: "Reopens soon",
      available_from: "Reopens on the {{date}}",
    },
  },
  de: {
    translation: {
      type_your_city: "Gib deine Stadt ein...",
      no_partners_in_area:
        "Leider konnten wir keine Partner in dieser Gegend finden.",
      suggest_partner: "Schlag uns einen Partner vor!",
      today: "Heute",
      hourSuffix: "Uhr",
      website: "Zur Webseite",
      coming_soon: "Bald bei VYTAL",
      available_soon: "Bald wieder für euch da",
      available_from: "Ab dem {{date}} wieder für euch da",
    },
  },
  fr: {
    translation: {
      type_your_city: "Saisissez votre ville...",
      no_partners_in_area:
        "Nous n'avons malheureusement pas trouvé de restos dans ce secteur.",
      suggest_partner: "Recommandez un resto !",
      today: "Aujourd'hui",
      hourSuffix: "",
      website: "Visiter le site",
      coming_soon: "Bientôt disponible",
      available_soon: "Réouvre bientôt",
      available_from: "Réouvre le {{date}}",
    },
  },
};

export default translations;
