const CLOUD_IMG_DEFAULT_PATH = "https://content.pickpack.de/width/700/";
const CLOUD_IMG_V7 = "https://content.pickpack.de/v7/";
const S3_IMG_DEFAULT_PATH =
  "https://s3.eu-central-1.amazonaws.com/products.pickpack.de/";

// Regex in order to take only the filter in the url coming from the backend
const filterRegex = /^.*?(?=\/)/;

export const getImageUrl = (width, imageUrl, options = {}) => {
  try {
    // default to the initial url in case later conditions don't match
    let returnUrl = imageUrl;
    if (!imageUrl) {
      return undefined;
    }

    // get real pixel width based on device pixel ratio
    /*const pixelRatioWidth = PixelRatio.getPixelSizeForLayoutSize(width)

        let adjustedWidth
        if (pixelRatioWidth > 100) {
            // round to the upper hundred (150 -> 200)
            adjustedWidth = Math.ceil(pixelRatioWidth / 100) * 100
        } else {
            // round to the upper decade (15 -> 20)
            adjustedWidth = Math.ceil(pixelRatioWidth / 10) * 10
        }*/

    // updates the url with requested width
    const buildUrlFromCloudImg = (requestedWidth, filePath) =>
      `https://content.pickpack.de/width/${requestedWidth}/${filePath}`;

    // updates the url with requested width and injects "/foil1/_products_/" which is missing in s3 urls
    const buildUrlFromS3 = (requestedWidth, filePath) =>
      `https://content.pickpack.de/width/${requestedWidth}/${options.filter}/_products_/${filePath}`;

    if (imageUrl.startsWith(CLOUD_IMG_DEFAULT_PATH)) {
      const filePath = imageUrl.slice(CLOUD_IMG_DEFAULT_PATH.length);
      // If there is a filter option we want to use the filter from the option
      if ("filter" in options) {
        // find the filter in the filePath
        const filter = filterRegex.exec(filePath);

        // Remove the filter from the file Path
        const filePathWithoutFilter = filePath.replace(filter, options.filter);
        returnUrl = buildUrlFromCloudImg(width, filePathWithoutFilter);
      } else {
        returnUrl = buildUrlFromCloudImg(width, filePath);
      }
    } else if (imageUrl.startsWith(S3_IMG_DEFAULT_PATH)) {
      // If there is no precise filter we want to use the basic foil1
      if (!("filter" in options)) {
        // eslint-disable-next-line no-param-reassign
        options.filter = "foil1";
      }
      const filePath = imageUrl.slice(S3_IMG_DEFAULT_PATH.length);
      returnUrl = buildUrlFromS3(width, filePath);
    } else {
      // Wrap all non matching in new v7 cdn request
      returnUrl = imageUrl.startsWith(CLOUD_IMG_V7)
        ? imageUrl
        : `${CLOUD_IMG_V7}${imageUrl}`;
      // If there is no precise filter we want to use the basic foil1
      let additionalFilters = "";
      if (options && options.filter === "twebp") {
        additionalFilters += "&force_format=webp";
      }
      returnUrl = `${returnUrl}?width=${width}&org_if_sml=1${additionalFilters}`;
    }
    return returnUrl;
  } catch (error) {
    /*Sentry.captureMessage(`Error in getImageUrl util: ${error}`, {
            level: SentrySeverity.Warning,
        })*/
    console.log(error);
    return imageUrl;
  }
};
