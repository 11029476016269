import styled from "styled-components/macro";
import React, { Component } from 'react';
import { COLOR, BREAKPOINTS } from "../styles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ReactComponent as Clock } from "../assets/clock.svg";
import { ReactComponent as PinSelected } from "../assets/pin-selected.svg";
import { ReactComponent as Wolt } from "../assets/ServiceLogoWolt.svg";
import { ReactComponent as Lieferando } from "../assets/ServiceLogoLieferando.svg";
import { ReactComponent as Eatic } from "../assets/ServiceLogoEatic.svg";
import { ReactComponent as Mjam } from "../assets/ServiceLogoMjam.svg";
import { ReactComponent as HabGusta } from "../assets/ServiceLogoHabGusta.svg";
import { ReactComponent as Preorder } from "../assets/ServiceLogoVytalPickup.svg";
import { ReactComponent as Delivery } from "../assets/ServiceLogoVytalDelivery.svg";
import {getImageUrl} from "../utils/imageUrl";

export const SubContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  bottom: 50px; /* Adjust the value as needed */
  z-index: 1;   /* Adjust the value to ensure proper layering */
  /* Add other styling properties as needed */
  bottom: 50px;
  z-index: 1;
  position: absolute;
  width: 100% !important;
`;


export const StoreCardContainer = styled.div`
  height: fit-content;
  display: flex;
  width: 100% !important;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
`;

const APP_ICON_WIDTH = 38;
const APP_ICON_BORDER_RADIUS = 8;

export const VytalInnerListContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  margin: 0;
  padding-right: 0;
  height: 100%;

  @media screen and (min-width: ${BREAKPOINTS.laptop}px) {
    margin: 0 1em;
    padding-right: 1em;
    min-width: 630px;
  }
`;


export const WoltAppIcon = styled(Wolt)`
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_WIDTH}px;
  background-color: ${COLOR.WOLT};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin-left: 5px;
`;

export const LieferandoAppIcon = styled(Lieferando)`
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_WIDTH}px;
  background-color: ${COLOR.LIEFERANDO};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin-left: 5px;
`;

export const MjamAppIcon = styled(Mjam)`
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_WIDTH}px;
  background-color: ${COLOR.MJAM};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin-left: 5px;
`;

export const EaticAppIcon = styled(Eatic)`
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_WIDTH}px;
  background-color: ${COLOR.EATIC};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin-left: 5px;
`;

export const HabGustaAppIcon = styled(HabGusta)`
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_WIDTH}px;
  background-color: ${COLOR.HAB_GUSTA};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin-left: 5px;
`;

export const DeliveryAppIcon = styled(Delivery)`
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_WIDTH}px;
  background-color: ${COLOR.VY_GREEN};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin-left: 5px;

  &.path {
    fill: ${COLOR.VY_BLACK};
  }
`;

export const PreorderAppIcon = styled(Preorder)`
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_WIDTH}px;
  background-color: ${COLOR.VY_GREEN};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin-left: 5px;
`;

export const StoreBanner = styled.div`
  flex-shrink: 0;
  position: relative;
  display: flex;
  height: 100px;
  transition: width 0.3s ease-out;
  z-index: 1;
  min-width: 433px !important;
  width: 100% !important;
  max-width: 633px !important;
  @media screen and (min-width: ${BREAKPOINTS.laptop}px) {
    width: ${(props) => (props.selected ? "235px" : "180px")};
    height: auto;
  }
`;

export const LazyBannerImage = styled(LazyLoadImage)`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const OverlayContainer = styled.div`
  display: flex;
  background-color: #000000b3;
  color: ${COLOR.WHITE};
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
`;

export const OverlayText = styled.p`
  text-align: center;
  line-height: 1.3;
`;

export const StoreCardPin = styled(PinSelected)`
  width: 36px;
  height: 40px;
  margin-right: 16px;
`;

export const StoreDetails = styled.div`
  margin: 16px;
  overflow: hidden;
  order: 1;

  @media screen and (min-width: ${BREAKPOINTS.laptop}px) {
    order: 0;
    margin: 1em 0 1em 1em;
    padding-right: 1em;
    width: 100%;
  }
`;

export const StoreHeader = styled.div`
  align-items: center;
  margin-bottom: 1em;
  display: ${(props) => (props.selected ? "flex" : "none")};
`;

export const StoreName = styled.h3`
  margin: 0;
  font-size: 20px;
  line-height: 1.2;
  font-weight: bold;
`;

export const StoreAddress = styled.p`
  font-size: 16px;
  line-height: 1.3;
  margin: 0.2em 0.5em 0.2em 0;
`;

export const WrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ClockIcon = styled(Clock)`
  width: 16px;
  height: 16px;
  margin-right: 0.2em;
`;

export const StoreHours = styled.div`
  font-size: 16px;
  line-height: 1.3;
  margin-right: 0.5em;
  align-items: center;
  display: flex;
  flex-shrink: 0;
`;

export const WebsiteContainer = styled.div`
  font-size: 16px;
  line-height: 1.3;
  align-items: center;
  display: flex;
`;

export const StoreWebsite = styled.a`
  font-size: 16px;
  color: ${COLOR.VY_GREEN};
  text-decoration: none;

  &:hover {
    color: ${COLOR.VY_GREEN};
    text-decoration: underline;
  }
`;

export const StoreTxt = styled.p`
  margin: 0.3em 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;
`;

const CardWrapper = styled.div`
  min-width: 433px !important;
  width: 100% !important;
  max-width: 633px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 2; 
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  pointer-events: initial;
`;

const StoreImage = styled.img`
  height: 80px;
  max-height: 100px;
  object-fit: fill;
`;

const StoreBannerImage = styled.img`
  height: 80px;
  width: 100%;
  object-fit: cover;
`;

const StoreNameDiv = styled.div`
  height: fit-content;
  width: 100%;
  max-width: 633px !important;
  overflow: hidden;
  display: flex;
  justify-content: start;
  align-items: center;
`;

const StoreNameH1 = styled.h1`
  height: fit-content;
  overflow: hidden;
  margin-left: 20px;
  margin-top: 0px !important; 
  margin-bottom: 0px !important;
`;

const ReturnTextDiv = styled.div`
  min-width: 433px !important;
  width: 100% !important;
  max-width: 633px !important;
  height: fit-content;
  display: flex;
  justify-content: start;
  align-items: center;
`;


const ReturnText = styled.h2`
  font-size: 16px;  
  margin-top: 10px;
  text-align: left; /* Set text alignment to left */
  margin-left: 20px; /* Add some left margin for spacing */
  margin-bottom: 0px !important;
  margin-top: 0px !important;
`;

const StoreBowlsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StoreBowlImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 5px;
`;

const ScrollableImagesContainer = styled.div`
  width: 100%;
  overflow-x: ${props => props.scrollable ? 'auto' : 'hidden'};
  display: flex;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 10px;
  
  
  /* Style the scrollbar */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: ${COLOR.VY_GREEN} transparent; /* For Firefox */

  &::-webkit-scrollbar {
    width: 6px; /* Set the width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* Background color of the track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLOR.VY_GREEN}; /* Color of the thumb */
    border-radius: 3px;
`;


class StoreCard extends Component {
  render() {
    const { store } = this.props;
    if (!store) {
        return (
            <div className="card">
                <div>
                    <h1>Loading...</h1>
                </div>
            </div>
        );
    }

    const { name, banner, image_urls: imageUrls, brand, accepted_container_types: acceptedContainerTypes  } = store

    const updatedStoreImgs = imageUrls.map(url => getImageUrl(100, url));

    return (
      <CardWrapper className="card">
        <ScrollableImagesContainer>
            {updatedStoreImgs.length < 5 ?
                <>{banner && <StoreBannerImage src={getImageUrl(500, banner)} alt="Store banner" />}</>
            :
          updatedStoreImgs.map((imgUrl, index) => (
            <StoreImage
              key={index}
              src={imgUrl}
              alt={`Store Image ${index}`}
            />
          ))}
        </ScrollableImagesContainer>
        {name && (
          <StoreNameDiv>
            <StoreNameH1>{name}</StoreNameH1>
          </StoreNameDiv>
        )}
        <ReturnTextDiv>
          <ReturnText>Rückgabe von:</ReturnText>
        </ReturnTextDiv>
          <ScrollableImagesContainer scrollable={true}>
          {acceptedContainerTypes.map((containerType, index) => (
            <StoreBowlImage
              key={index}
              src={getImageUrl(50, containerType.image_url)}
              alt={`Bowl ${index + 1}`}
            />
          ))}
        </ScrollableImagesContainer>
      </CardWrapper>
    );
  }
}

export default StoreCard;

/*
<img src="./assets/1.avif" className="card-img-top" alt="" />
      <div className="card-body">
        <h5 className="card-title">Card title</h5>
        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
        <a href="/" className="btn btn-primary">Go somewhere</a>
      </div>

              <h2>{storeData.store}</h2>
        <p>{storeData.store.image_urls[0]}</p>
        <p>{storeData.store.brand.name}</p>
*/


