
// const LANGUAGE = document.querySelector('meta[name="language"]').content;
/*

export const getStoreQuery = gql`
  query getStoresInViewpoint($north_east: LonLatIn!, $south_west: LonLatIn!) {
    getStoresInViewpoint(north_east: $north_east, south_west: $south_west) {
      id
      name
      banner
      website
      coming_soon,
      available_soon,
      available_from,
      can_preorder,
      can_deliver,
      has_wolt
      has_lieferando
      has_mjam
      has_habgusta
      has_eatic
      lonlat {
        latitude
        longitude
      }
      address{
        streetname
        streetno
        city
      }
    }
  }
`;


const App = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const [zoom, setZoom] = useState(ZOOM)
  const [northEast, setNorthEast] = useState({"latitude": 52.54860423411774, "longitude": 13.433100000000039})
  const [southWest, setSouthWest] = useState({"latitude": 52.487885645078684, "longitude": 13.379100000000008})
  const [bounds, setBounds] = useState(null)
  const [stores, setStores] = useState([])
  const { loading, error, data } = useQuery(getStoreQuery, {
    variables: {
      north_east: northEast,
      south_west: southWest,
    },
    skip: false
  });


  useEffect(() => {
    if(data && data.getStoresInViewpoint){
      const rawStores = data.getStoresInViewpoint
      let filteredStores;
      if (WOLT === "yes") {
        filteredStores = rawStores.filter((store) => store.has_wolt);
      } else {
        filteredStores = rawStores;
      }
      setStores(filteredStores)
    }
  }, [data])




  const onBoundsChanged = (e) => {
    setNorthEast({latitude: e.bounds.ne.lat, longitude: e.bounds.ne.lng})
    setSouthWest({latitude: e.bounds.sw.lat, longitude: e.bounds.sw.lng})
    setBounds(e.bounds)
    setZoom(e.zoom)
  }

  return (
      <AppContainer>
        {error && <LoadingContainer>Error! ${error.message}</LoadingContainer>}
        <VytalPartners
            language={language}
            defaultCenter={{ lat: LAT, lng: LNG }}
            defaultZoom={ZOOM}
            showSearch={SHOWSEARCH === "yes"}
            stores={stores}
            currentZoom={zoom}
            currentBounds={bounds}
            onBoundsChanged={onBoundsChanged}
            loading={loading}
        />
      </AppContainer>
  );
};

export default App;*/


import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import VytalPartners from "./components/VytalPartners";
import { AppContainer, LoadingContainer } from "./AppContainer";
import { SubContainer } from "./components/SubComponents";
import { StoreCardContainer } from "./components/SubComponents";
import StoreCard from "./components/SubComponents";
import { gql, useQuery } from "@apollo/client";

const LAT = parseFloat(document.querySelector('meta[name="lat"]').content);
const LNG = parseFloat(document.querySelector('meta[name="lng"]').content);
const ZOOM = parseFloat(document.querySelector('meta[name="zoom"]').content);
const SHOWSEARCH = document.querySelector('meta[name="showSearch"]').content;
const WOLT = document.querySelector('meta[name="wolt"]').content;

export const getStoreQuery = gql`
query getStoreExtraInfoInMap($id: ID!) {
  store(id: $id) {
    ...criticalStoreDataForMap
    ...extraStoreDataForInlay
  }
}
fragment criticalStoreDataForMap on Store {
  id
  brand {
    id
    name
  }
  name
  banner
  geo_hash
  coming_soon
  available_from
  available_soon
  opening_hours_multi {
    day
    time
  }
}
fragment extraStoreDataForInlay on Store {
  id
  can_deliver
  can_preorder
  ordering_services {
    ordering_service_type
    url
  }
  product_tags
  image_urls
  accepted_container_types {
    id
    image_url
    name
  }
}
`;

const App = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const [zoom, setZoom] = useState(ZOOM);
  const [bounds, setBounds] = useState(null);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const { data: storeData, loading: storeLoading, error: storeError } = useQuery(getStoreQuery, {
    variables: { id: selectedStoreId },
    skip: !selectedStoreId,
  });

  useEffect(() => {
    fetch('https://vytal-website.s3.eu-central-1.amazonaws.com/store-map-export/stores.json')
      .then(response => response.json())
      .then(stores => {
        setStores(stores)
        setLoading(false)
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  }, [])

  const onBoundsChanged = (e) => {
    setBounds(e.bounds)
    setZoom(e.zoom)
  }

  return (
    <AppContainer>

      {error && <LoadingContainer>Error! ${error.message}</LoadingContainer>}
      <VytalPartners
        onStoreSelect={setSelectedStoreId}
        language={language}
        defaultCenter={{ lat: LAT, lng: LNG }}
        defaultZoom={ZOOM}
        showSearch={false /* disabled for now, until we use a google places api alternative (here api) // SHOWSEARCH === "yes" */}
        stores={stores}
        currentZoom={zoom}
        currentBounds={bounds}
        onBoundsChanged={onBoundsChanged}
        loading={loading}
        style={{ marginBottom: "-400px" }} /* Adjust the margin value as needed */
      />
      <SubContainer>
        {
          storeData &&
          <StoreCardContainer>
            <StoreCard
              store={storeData.store}
            ></StoreCard>
          </StoreCardContainer>
        }
      </SubContainer>
    </AppContainer>
  );
};

export default App;

/*
      <SubContainer>
        {/* Here you can put whatever you want in the new container }
        </SubContainer>

                <ProductContainer></ProductContainer>


                          storeName={storeData.store}
          storeImg={storeData.store.image_urls[0]}
          storeBrandName={storeData.store.brand.name}

          storeName={storeData.store}
          storeImg={storeData.store.image_urls[0]}
          storeBrandName={storeData.store.brand.name}
*/
