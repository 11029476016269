import styled from "styled-components/macro";
import { COLOR } from "../styles";
import { ReactComponent as Cluster } from "../assets/cluster.svg";
import { ReactComponent as Pin } from "../assets/pin.svg";
import { ReactComponent as PinSelected } from "../assets/pin-selected.svg";

export const VytalMapInnerContainer = styled.div`
  width: 100%;
`;

export const ClusterContainer = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  top: -40px;
  left: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ClusterCount = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${COLOR.VY_GREEN};
  position: relative;
  top: -4px;
`;

export const ClusterIcon = styled(Cluster)`
  position: absolute;
  z-index: -1;
`;

export const PinContainer = styled(Pin)`
  width: 36px;
  height: 40px;
  position: relative;
  top: -40px;
  left: -18px;
  cursor: pointer;
`;

export const PinContainerSelected = styled(PinSelected)`
  width: 36px;
  height: 40px;
  position: relative;
  top: -40px;
  left: -18px;
  cursor: pointer;
`;
