export const COLOR = {
  WHITE: "#FFFFFF",
  TRANSPARENT: "#00000000",
  VY_GREEN: "#1CC282",
  VY_WHITE: "#f5f5f5",
  VY_BLACK: "#1C1A20",
  VY_LIGHT_GREY: "#E6E6E6",
  VY_LILAC: "#D2A7FF",
  VY_RED: "#FF3800",

  // DELIVERY SERVICES
  LIEFERANDO: "#FF8000",
  WOLT: "#65BEE2",
  HAB_GUSTA: "#ff6158",
  EATIC: "#355a5e",
  MJAM: "#2EFF55",
  FOOD_PANDA: "#d70f64",

  // OVERLAY
  OVERLAY_60: "#00000099",

  // ADDED TO FIGMA FOR LEGACY
  GREY_DARK: "#2B363E",
};

// taken from webflow
export const BREAKPOINTS = {
  mobileLandscape: 480,
  tablet: 768,
  laptop: 992,
  desktop: 1280,
};
