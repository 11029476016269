import styled from "styled-components/macro";
import { COLOR, BREAKPOINTS } from "../styles";
import { ReactComponent as Search } from "../assets/search.svg";
import Lottie from "lottie-react";

export const VytalPartnerSearchContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SearchInputContainer = styled.div`
  flex-grow: 0;
  margin-bottom: 32px;
  position: relative;

  &:focus {
    outline: none;
  }
`;

export const SearchInput = styled.input`
  border-radius: 30em;
  border-style: none;
  padding: 1em 2em 1em 60px;
  width: 300px;

  &:focus {
    outline: none;
  }

  @media screen and (min-width: ${BREAKPOINTS.mobileLandscape}px) {
    width: 400px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}px) {
    // datalist doesn't seem to like wider widths than 450px
    width: 250px;
  }
`;

export const SearchSuggestionsContainer = styled.div`
  position: absolute;
  background: white;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  z-index: 300;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  height: ${(props) => (props.showSuggestions ? "auto" : "0px")};
  overflow: hidden;
`;

export const SearchSuggestion = styled.button`
  padding: 16px;
  border-bottom: 1px solid #53b84842;
  width: 100%;
  text-align: left;
  color: ${(props) => (props.selected ? COLOR.WHITE : "inherit")};
  background-color: ${(props) =>
    props.selected ? COLOR.VY_GREEN : COLOR.WHITE};

  &:hover {
    background-color: ${COLOR.VY_GREEN};
    color: ${COLOR.WHITE};
  }

  &:focus {
    outline: none;
  }

  &:last-child {
    border: none;
  }
`;

export const SearchIconContainer = styled(Search)`
  width: 36px;
  height: 40px;
  position: absolute;
  left: 18px;
  top: 5px;

  & path {
    fill: ${COLOR.VY_GREEN};
  }
`;

export const VytalPartnersContainer = styled.div`
  width: 100%;
  height: 10px;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  position: relative;
  align-items: center;
`;

export const VytalMapContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 300px;
  position: relative;
  flex-grow: 1;
  height: auto;
`;

export const NoStores = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1em;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
`;
