import { useState, useRef, useEffect } from "react";
import propTypes from "prop-types";
import useSupercluster from "use-supercluster";
import VytalList from "./VytalList";
import VytalMap from "./VytalMap";
import { useTranslation } from "react-i18next";
import {
  VytalPartnerSearchContainer,
  VytalPartnersContainer,
  VytalMapContainer,
  //VytalListContainer,
  NoStores,
  SearchInput,
  SearchInputContainer,
  SearchIconContainer,
  SearchSuggestionsContainer,
  SearchSuggestion,
} from "./VytalPartnersContainer";
import { ColorRing } from "react-loader-spinner";
import VytalLoader from "../assets/Lottie-Loader-fast-data.json"
import Lottie from "lottie-react";

const CITYZOOM = 10;
// Google always seems to return 5 suggestions
// https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
const MAXSUGGESTIONS = 5;
const STOREZOOM = 14;

const VytalPartners = ({
  onStoreSelect,
  language,
  defaultCenter,
  defaultZoom,
  stores,
  showSearch, onBoundsChanged, currentBounds, currentZoom, loading
}) => {
  const { t } = useTranslation();
  const mapRef = useRef();
  const [googleMaps, setGoogleMaps] = useState(null);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);
  const [predictions, setPredictions] = useState(null);
  const [input, setInput] = useState("");
  const [selectedStore, setSelectedStore] = useState({});
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const handleStoreClick = (storeId) => {
    //event.preventDefault();
    onStoreSelect(storeId);
  };


  useEffect(() => {
    if (googleMaps) {
      const places = googleMaps.places;
      const acS = new places.AutocompleteService();
      const pS = new places.PlacesService(mapRef.current);
      setAutocompleteService(acS);
      setPlacesService(pS);
    }
  }, [googleMaps]);

  useEffect(() => {
    if (showSearch) {
      const map = mapRef.current;
      if (input && predictions) {
        predictions.forEach((prediction) => {
          const { description, place_id } = prediction;
          if (description === input) {
            placesService.getDetails(
              { placeId: place_id, fields: ["geometry.location"] },
              (details) => {
                const newCenter = {
                  lat: details.geometry.location.lat(),
                  lng: details.geometry.location.lng(),
                };
                // const currentZoom = map.zoom;
                map.setZoom(CITYZOOM);
                map.panTo(newCenter);
                setShowSuggestions(false);
              }
            );
          }
        });
      }
      if (input) {
        const autocompletionRequest = {
          input: input,
          types: ["(cities)"],
          bounds: map.getBounds(),
        };
        autocompleteService.getPlacePredictions(
          autocompletionRequest,
          (predictions) => {
            setPredictions(predictions);
          }
        );
      } else {
        setPredictions(null);
      }

      setSelectedSuggestion(0);
      const inputElement = document.getElementById("searchInput");
      inputElement.value =
        inputElement.value !== input ? input : inputElement.value;
    }
  }, [input]);

  // selectedStoreId needs to be set first to accurately calculate scroll distance
  useEffect(() => {
    if (selectedStore && mapRef.current) {
      // TODO: If selected store is inside a cluster, break up the cluster
      // TODO: Pan selected pin to center of map
      // TODO: Problem is that changes in bounding box trigger changes in list and scroll calculations get messed up. Google API does not fire any "onPanFinished" event so I couldn't sequence them.

      /* const map = mapRef.current;
      const currentZoom = map.zoom;
      map.setZoom(currentZoom >= STOREZOOM ? currentZoom : STOREZOOM);

      // https://www.leighhalliday.com/google-maps-clustering
      const expansionZoom = Math.min(
          supercluster.getClusterExpansionZoom(id),
          20
      );
      map.setZoom(expansionZoom);
      // Pan map to store
      const { lonlat } = selectedStore;

      map.panTo({
        lat: lonlat.latitude,
        lng: lonlat.longitude,
      }); */

      // Scroll list to selected card
      const { id } = selectedStore;
      const selectedStoreCard = document.getElementById(id);
      if (selectedStoreCard) {
        const selectedStoreCardTop = selectedStoreCard.offsetTop;
        // TODO: Get current card height through DOM, for now 190px
        const defaultCardHeight = 190;
        const cardsContainer = document.getElementById("VytalList");
        const cardsContainerHeight = cardsContainer.clientHeight;
        const offsetPosition =
          selectedStoreCardTop -
          cardsContainerHeight / 4 +
          defaultCardHeight / 2;
        cardsContainer.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [selectedStore]);


  // Cluster stores for map
  const points = stores.map((store) => ({
    type: "Feature",
    properties: { cluster: false, storeId: store.id, store: store },
    geometry: {
      type: "Point",
      coordinates: [store.lonlat.longitude, store.lonlat.latitude],
    },
  }));

  const currentBbx = currentBounds ? [currentBounds.nw.lng, currentBounds.se.lat, currentBounds.se.lng, currentBounds.nw.lat] : null

  const { supercluster } = useSupercluster({
    points,
    currentBounds: currentBbx,
    currentZoom,
    options: {
      radius: 90,
      maxZoom: 14,
    },
  });

  // Collect all stores in the bounding box for list
  let clusters = [];
  let bbStores = [];
  if (supercluster && currentBbx && currentZoom) {
    supercluster.load(points)
    clusters = supercluster.getClusters(currentBbx, currentZoom);
    clusters.forEach((c) => {
      const {
        properties: { cluster, cluster_id, store },
      } = c;
      if (!cluster) {
        bbStores.push(store);
      } else {
        const clusterChildren = supercluster.getLeaves(cluster_id, 100);
        clusterChildren.forEach((c) => {
          const {
            properties: { store },
          } = c;
          bbStores.push(store);
        });
      }
    });
  }

  const handleSuggestionClick = (e) => {
    const value = e.target.textContent;
    setInput(value);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput(value);
    setShowSuggestions(true);
  };

  const iframeWidth = "100%";
  const iframeHeight = "500px";


  return (
    <VytalPartnerSearchContainer
      onClick={() => {
        setShowSuggestions(false);
      }}
    >
      {showSearch && (
        <SearchInputContainer>
          <SearchIconContainer />
          <SearchInput
            autoComplete="off"
            list="autoCompleteServiceSuggestions"
            id="searchInput"
            placeholder={t("type_your_city")}
            onFocus={(e) => {
              e.target.select();
            }}
            onClick={(e) => {
              setShowSuggestions(true);
              e.target.select();
            }}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "ArrowDown") {
                const int =
                  selectedSuggestion === MAXSUGGESTIONS - 1
                    ? MAXSUGGESTIONS - 1
                    : selectedSuggestion + 1;
                setSelectedSuggestion(int);
              }
              if (e.key === "ArrowUp") {
                const int =
                  selectedSuggestion === 0 ? 0 : selectedSuggestion - 1;
                setSelectedSuggestion(int);
              }
              if (e.key === "Enter") {
                const { description } = predictions[selectedSuggestion];
                setInput(description);
              }
            }}
          />

          <SearchSuggestionsContainer
            id="autoCompleteServiceSuggestions"
            showSuggestions={showSuggestions}
          >
            {predictions &&
              predictions.map((prediction, key) => {
                const { description } = prediction;
                const selected = key === selectedSuggestion;

                return (
                  <SearchSuggestion
                    key={key}
                    onClick={handleSuggestionClick}
                    selected={selected}
                  >
                    {description}
                  </SearchSuggestion>
                );
              })}
          </SearchSuggestionsContainer>
        </SearchInputContainer>
      )}
      <VytalPartnersContainer>
        <VytalMapContainer>
          {loading && (
            <div style={{ width: '40px', height: '40px', position: 'absolute', top: 10, left: 10, zIndex: 1000 }}>
              <Lottie autoplay={true} loop={true} animationData={VytalLoader} />
            </div>
          )}
          <VytalMap
            iframeWidth={iframeWidth}
            iframeHeight={iframeHeight}
            language={language}
            defaultCenter={defaultCenter}
            defaultZoom={defaultZoom}
            onGoogleApiLoaded={({ map, maps }) => {
              mapRef.current = map;
              setGoogleMaps(maps);
            }}
            onChange={(e) => {
              onBoundsChanged(e)
            }}
            clusters={clusters}
            onClusterClick={(cluster) => {
              const {
                id,
                geometry: {
                  coordinates: [longitude, latitude],
                },
              } = cluster;
              const expansionZoom = Math.min(
                supercluster.getClusterExpansionZoom(id),
                20
              );
              mapRef.current.setZoom(expansionZoom);
              mapRef.current.panTo({
                lat: latitude,
                lng: longitude,
              });
            }}
            onPinClick={(cluster) => {
              setSelectedStore(cluster.properties.store);
              handleStoreClick(cluster.properties.store.id);
            }}
            selectedStore={selectedStore}
          />
        </VytalMapContainer>
      </VytalPartnersContainer>
    </VytalPartnerSearchContainer>
  );
};

VytalPartners.defaultProps = {
  language: "en",
  // Köln
  defaultCenter: { lat: 50.935173, lng: 6.953101 },
  defaultZoom: 12,
  stores: [],
  showSearch: false,
};
/*


                  <VytalListContainer>
          <VytalList
            language={language}
            stores={bbStores}
            selectedStore={selectedStore}
            handleStoreClick={setSelectedStore}
          />
          {bbStores.length === 0 && mapRef.current && !loading && (
            <NoStores>
              <p>{t("no_partners_in_area")} </p>
              <p>
                <a
                  href="https://vytal.org/vytal-fuer-konsumenten#konsuinfo"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("suggest_partner")}
                </a>{" "}
                🙏✌️
              </p>
            </NoStores>
          )}
        </VytalListContainer>
*/
VytalPartners.propTypes = {
  language: propTypes.string,
  defaultCenter: propTypes.object,
  defaultZoom: propTypes.number,
  stores: propTypes.array,
  showSearch: propTypes.bool,
  onBoundsChanged: propTypes.func
};

export default VytalPartners;
