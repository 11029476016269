import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  VytalInnerListContainer,
  OverlayContainer,
  OverlayText,
  GradientContainer,
  PreorderAppIcon,
  WoltAppIcon,
  StoreAddress,
  StoreBanner,
  StoreCard,
  StoreCardPin,
  StoreDetails,
  StoreHeader,
  StoreName,
  LazyBannerImage,
  StoreWebsite,
  WrapContainer,
  WebsiteContainer,
  DeliveryAppIcon,
  LieferandoAppIcon,
  EaticAppIcon,
  HabGustaAppIcon,
  MjamAppIcon,
} from "./VytalListContainer";
import { getImageUrl } from "../utils/imageUrl";
import moment from "moment";
import { COLOR } from "../styles";

// Don't think it is necessary to show the opening hours but don't want to delete yet
// import { enhanceOpeningHoursMulti } from "../utils/time";
// const D = new Date();
// const TODAY = D.getDay();

const BANNERWIDTH = 500;

const VytalList = ({
  id,
  language,
  stores,
  selectedStore,
  handleStoreClick,
}) => {
  const { t } = useTranslation();
  return (
    <VytalInnerListContainer id={id}>
      {stores &&
        stores.map((store) => {
          const {
            address: { streetname = "", streetno = "", city = "" } = {}, //real one: address: { streetname, streetno, city },
            banner,
            can_preorder,
            can_deliver,
            has_wolt,
            has_lieferando,
            has_habgusta,
            has_eatic,
            has_mjam,
            coming_soon,
            available_soon,
            available_from,
            name,
            // opening_hours_multi,
            website,
          } = store;
          const bannerSmall = getImageUrl(BANNERWIDTH, banner, {
            filter: "twebp",
          });
          const isSelected = selectedStore.id === store.id;

          return (
            <StoreCard
              key={store.id}
              id={store.id}
              onClick={() => {
                handleStoreClick(store);
              }}
              selected={isSelected}
            >
              <StoreBanner selected={isSelected}>
                <LazyBannerImage
                  src={bannerSmall}
                  effect="blur"
                  title={`VYTAL - ${name}`}
                  width="100%"
                />
                {coming_soon && !available_soon && (
                  <OverlayContainer>
                    <OverlayText>{t("coming_soon")}</OverlayText>
                  </OverlayContainer>
                )}
                {available_soon && !available_from && (
                  <OverlayContainer>
                    <OverlayText>{t("available_soon")}</OverlayText>
                  </OverlayContainer>
                )}
                {available_soon && available_from && (
                  <OverlayContainer>
                    <OverlayText>
                      {t("available_from", {
                        date: moment(available_from).format("DD.MM"),
                      })}
                    </OverlayText>
                  </OverlayContainer>
                )}
              </StoreBanner>
              <StoreDetails>
                <StoreHeader selected={isSelected}>
                  <StoreCardPin />
                  {can_preorder && !can_deliver && (
                    <PreorderAppIcon color={COLOR.VY_BLACK} />
                  )}
                  {can_deliver && <DeliveryAppIcon color={COLOR.VY_BLACK} />}
                  {has_wolt && <WoltAppIcon />}
                  {has_lieferando && <LieferandoAppIcon />}
                  {has_eatic && <EaticAppIcon />}
                  {has_mjam && <MjamAppIcon />}
                  {has_habgusta && <HabGustaAppIcon />}
                </StoreHeader>
                <StoreName>{name}</StoreName>
                <WrapContainer>
                  <StoreAddress>
                    {streetname} {streetno}, {city}
                  </StoreAddress>
                  {website && (
                    <WebsiteContainer>
                      <StoreWebsite
                        href={website}
                        title={name}
                        target="_blank"
                        rel="noreferrer"
                      >
                        → {t("website")}
                      </StoreWebsite>
                    </WebsiteContainer>
                  )}
                </WrapContainer>
                <GradientContainer selected={isSelected} />
              </StoreDetails>
            </StoreCard>
          );
        })}
    </VytalInnerListContainer>
  );
};

VytalList.defaultProps = {
  id: "VytalList",
  language: "en",
  stores: [],
  selectedStore: {},
  handleStoreClick: () => {},
};

VytalList.propTypes = {
  id: PropTypes.string,
  language: PropTypes.string,
  stores: PropTypes.array,
  selectedStore: PropTypes.object,
  handleStoreClick: PropTypes.func,
};

export default VytalList;